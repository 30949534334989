import React from 'react';

export default {
  id: 'SR',
  yes: 'Da',
  no: 'Ne',
  ok: 'U redu',
  for: 'za',
  in: 'u',
  cancel: 'Odustani',
  delete: 'Obriši',
  close: 'Zatvori',
  verify: 'Verifikuj',
  send: 'Pošalji',
  description: 'Opis',
  city: 'Grad',
  address: 'Adresa',
  optional: 'Opciono',
  verification: 'Verifikacija',
  tabs_fangouts: 'Fangout zahtevi',
  tabs_posts: 'Objave',
  tabs_requests: 'Zahtevi',
  tabs_profile: 'Profil',
  tabs_account: 'Nalog',
  tabs_users: 'Korisnici',
  fangouts_search: 'Pretraga',
  fangouts_bill: 'Račun',
  fangouts_fans: 'Fanova',
  fangouts_type: 'Tip',
  fangouts_time: 'Vreme',
  fangouts_complete: 'Potvrdi',
  CASUAL: 'Kežual',
  casual_description: '(piće, većera, slikanje, druženje...)',
  EDUCATION: 'Edukacija',
  education_description: '(prenos znanja bilo koje vrste)',
  PERFORMANCE: 'Nastup/Usluga',
  performance_description: '(svirka, stendap...)',
  FAN_PAYS: 'Fan plaća',
  SPLIT: 'Deli se',
  CELEBRITY_PAYS: 'Celebrity plaća',
  bill: 'račun',
  EUR: '€',
  USD: '$',
  most_popular: 'Najpopularniji',
  most_active: 'Najaktivniji',
  latest_fangouts: 'Najnoviji Fangouti',
  unavailable_atm: 'Korisnik je nedostupan',
  requests_title: 'Zahtevi',
  under_construction: 'U izradi...',
  profile_title: 'Profil',
  profile_status: 'Status',
  profile_share_refferal: 'Pozovi prijatelja i zaradi (klik za share)',
  profile_settings: 'Podešavanja',
  profile_personal_details: 'Profil',
  profile_payment_history: 'Istorija plaćanja',
  profile_referrals: 'Referali',
  profile_logout: 'Logout',
  profile_remove_account: 'Obrišite nalog',
  profile_remove_account_message1: 'Sigurno želite da obrišete profil?',
  profile_remove_account_message2: 'Ovaj postupak je bespovratan.',
  profile_help_title: 'Zašto verifikacija?',
  profile_help_message1:
    'Kao i na svim freelance platformama, kako bi osigurali bezbednost na platformi neophodna je indetifikacija korisnika uz pomoć ličnog dokumenta sa slikom (lična karta ili pasoš).',
  profile_help_message2:
    'Budite spokojni. Nakon verifikacije u bazu se upisuje samo broj isprave dok se fotografija trajno briše.',
  profile_help_ref_title: 'Referal link',
  profile_help_ref_message1:
    'Zaradite 3% od celokupnog prometa prijatelja koji je registrovan preko Vašeg referal linka, i dodatnih 2% od zarade svih naloga registrovanih preko njegovog referal linka. ',
  profile_help_ref_message2:
    'Vaša nagrada za referal od 3% i 2% ne utiče na zaradu korisnika registrovanog preko Vas.',
  increase_credibility:
    'Boljim predstavljanjem povećavate mogućnost da Vaši fangout zahtevi bude prihvaćeni',
  settings_title: 'Podešavanja',
  settings_enable_light_mode: 'Uključi svetli mod',
  settings_language: 'Jezik',
  settings_currency: 'Moneta',
  referrals_title: 'Referali',
  createFangout_title: 'Novi Fangout',
  editFangout_title: 'Uredi Fangout',
  requestFangout_title: 'Zahtev za Fangout',
  requestFangout_user_is_not_available:
    'Korisnik nije dostupan tog datuma ili na toj lokaciji',
  details_title: 'Detalji',
  payment_title: 'Plaćanje',
  label_title: 'Naziv događaja',
  label_location: 'Lokacija',
  label_date: 'Početak događaja',
  label_end_date: 'Kraj događaja',
  label_time: 'Vreme',
  label_type: 'Tip',
  label_bill: 'Račun',
  label_audience: 'Fanova/Publika',
  label_price: 'Fangout Cena',
  label_set_price: 'Postavi cenu',
  label_request: 'Zahtevaj',
  label_create: 'Kreiraj',
  label_edit: 'Ažuriraj',
  label_save: 'Sačuvaj',
  label_from: 'Od',
  label_to: 'Do',
  label_celebrity: 'Celebrity',
  label_my_fangouts: 'Moji Fangouti',
  label_duration: 'Trajanje',
  label_profession: 'Profesija',
  label_not_visible_to_others: '(vidljivo samo administratoru)',
  label_full_name: 'Puno ime i prezime',
  label_stage_name: 'Umetničko ime',
  label_bio: 'Bio',
  label_soc_networks: 'Društvene mreže',
  bank_info: 'Dodajte račun za isplatu zarade',
  label_bank_country: 'Država banke',
  label_bank_name: 'Ime banke',
  label_bank_account: 'Bankovni račun',
  attention_user_is_not_verified: '(Pažnja, korisnik nije verifikovan)',
  requests_received: 'Primljeni',
  requests_sent: 'Poslati',
  requests_history: 'Završeni',
  requests_confirm_title: 'Potvrda',
  requests_confirm_complete_content: (
    <div className={'content'}>
      Da li potvrđujete da Vam je usluga{' '}
      <div>
        <span className={'text-primary text-bold'}>pružena?</span>
      </div>
    </div>
  ),
  requests_confirm_content: function (approve) {
    return (
      <div className={'content'}>
        Da li ste sigurni da želite da{' '}
        {approve === 0 ? (
          <div>
            <span className={'text-primary text-bold'}>Odobrite</span> ovaj
            zahtev?
          </div>
        ) : (
          <div>
            <span className={'text-danger text-bold'}>Odbijete</span> ovaj
            zahtev?
          </div>
        )}
      </div>
    );
  },
  requests_dispute_help_title: 'Spor',
  requests_dispute_help_content:
    'Ukoliko smatrate da Vam usluga nije pružena budite slobodni da pokrenete spor. Neko iz administracije će Vas ubrzo kontaktirati radi razrešenja istog. Hvala.',
  requests_dispute_submitAction: 'Pokreni spor',
  requests_dispute_content:
    'Ovim potvrđujete da je došlo do spora. Molimo Vas da ukratko opišete šta se dogodilo.',
  requests_dispute_confirmation: 'Hvala. Neko će Vas uskoro kontaktirati.',
  requests_dispute_notification:
    'Spor je u toku. Uskoro će Vas kontaktirati neko od administratora.',
  has_set_the_price: 'je postavio cenu za Fangout',
  price_is_set_note:
    'Cena je podešena, sačekajte da korisnik plati ili poništi zahtev.',
  pay: 'Plati',
  message_placeholder: 'Pošaljite poruku',
  message_noConversation: 'Dogovorite detalje preko poruka',
  click_to_verify: 'klikni za verifikaciju',
  create_post: 'Kreiraj Post',
  new_post_title: 'Novi Post',
  post_caption: 'Opis',
  upload: 'Upload',
  save: 'Sačuvaj',
  toggle_push_notifications: 'Obaveštenja',
  fan_has_paid: 'Paid',
  remove: 'Obriši',
  sent: 'Poslato',
  help_new_fangout1:
    'Fangout je događaj koji povezuje Poznate ličnosti sa Fanovima na određeni način.',
  help_new_fangout2:
    'Može da bude profesionalnog, edukativnog ili neobaveznog tipa.',
  help_new_fangout3:
    'Fangout može da kreira bilo ko i kreiranje je besplatno. Broj kreiranih Fangouta je neograničen.',
  help_new_fangout4:
    'Prilikom kreiranja se unose detalji kao što su vreme, mesto, trajanje i cena događaja ali se o daljim specifičnostima korisnici dogovaraju preko chat-a na aplikaciji.',
  help_new_fangout5:
    'Nakon kreiranja Fangouta poželjno je da ga podelite sa svojim Fanovima kako bi ga što pre neko kupio.',
  help_new_fangout6:
    'Prilikom unosa cene imajte u vidu proviziju platforme od 20%.',
  support: 'Podrška',
  deleted: 'Obrisano',
  verify_message1:
    'Iz bezbednosnih razloga, samo verifikovani korisnici mogu da koriste sve opcije na platformi.',
  verify_message2:
    'Da bi verifikovali svoj profil potrebno je da pošaljete sliku lične karte ili pasoša.',
  profile_unverified: 'Profil NEVERIFIKOVAN',
  verify_first:
    'Iz bezbednosnih razloga samo verifikovani korisnici mogu da koriste sve opcije na platformi. Molimo Vas da se prvo verifikujte.',
  verify_be_patient:
    'Vaš profil je u procesu verifikacije. Hvala na strpljenju.',
  reset_pass_title: 'Nova lozinka',
  reset_pass_summary: 'Unesite novu lozinku.',
  reset_pass_new_password: 'Nova lozinka',
  reset_pass_repeat_password: 'Ponovite lozinku',
  forget_pass_heading: 'Zaboravljena lozinka',
  forget_pass_summary:
    'Molimo Vas da unesete Vašu e-mail adresu sa kojom ste registrovani kako bismo Vam poslali instrukcije za obnovu lozinke.',
  forget_pass_title: 'Proverite e-mail',
  forget_pass_message:
    'Poslali smo instrukcije za obnovu lozinke na Vaš e-mail.',
  publicProfile_subscribe: 'Prati',
  publicProfile_subscribed: 'Pratiš',
  publicProfile_subscribe_title: 'Zaprati korisnika',
  publicProfile_subscribe_help:
    'Budite obavešteni kada korisnik postane dostupan ili kreira Fangout događaj.',
  publicProfile_unsubscribe: 'Odprati',
  subscriptions_title: 'Praćenja',
  subscriptions_title_help: 'Korisnici i lokacije koje pratite.',
  availability_title: 'Dostupnost',
  availability_isAvailable: 'Dostupan za Fangout zahteve',
  availability_newAvailability:
    'Dodajte novi period od kad do kada ste dostupni',
  availability_help:
    'Ukoliko ste čekirali da ste dostupni za Fangout zahteve, a nemate dodate periode dostupnosti ispod, smatra se da ste dostupni sve vreme.',
  user_availability: 'Dostupnost korisnika',
  myagent_title: 'Fangout Agent',
  myagent_on: 'Uključeno',
  myagent_off: 'Isključeno',
  myagent_title_short: 'F-Agent',
  myagent_title_help:
    'Podesite Fangout Agenta koji će automatski postaviti cenu za određeni tip Fangout zahteva.',
  myagent_help_performance:
    'You can define max number of audience and charge upon duration of the engagement.',
  myagent_help_education:
    'You can define max number of audience on the class and charge per 1 hour.',
  myagent_help_casual:
    'You can define max number of audience, max duration and charge for fist and every next hour.',
  myagent_help_additinal:
    " If Fangout request doesn't fit into any of your rules price will not be set automatically and you will be able to set it manually.",
  myagent_h: 'sat',
  myagent_h_n: '.',
  myagent_h_first: '.',
  myagent_h_second: '.',
  myagent_h_third: '.',
  myagent_add_next_hour: 'Dodajte cenu za sledeći sat',
  myagent_h_evry_next: 'Svaki sledeći sat',
  myagent_max_audience: 'Maksimum prisutnih',
  myagent_max_duration: 'Maksimum trajanja',
  myagent_h_per_person: '1h po osobi',
  fout_autocompletion_label1: 'Auto potvrda za',
  fout_autocompletion_label2: 'Fangout će automatski biti potvrđen.',
  confirm_are_you_sure: 'Da li ste sigurni?',
  premium_feature: 'Premium opcija',
  premium_buy_year: 'Kupi Premium plan',
  premium_activate: 'Aktiviraj',
  premium_type_code: 'Koristite kod za aktivaciju',
  premium_desc_availability: (
    <div>
      <span style={{ fontWeight: 600 }}>Dostupnost</span> Vam omogućava da
      podesite datume i mesta u kojima želite da budete dostupni. <br />
      <br />
      Ovo će ograničiti korisnike da Vam šalju Fangout zahteve samo za mesta i u
      danima koje ste definisali što će Vam umnogome olakšati rad na platformi.
    </div>
  ),
  premium_desc_myagent: (
    <div>
      <span style={{ fontWeight: 600 }}>F-Agent</span> je Vaš zastupnik koji će
      prema podešavanjima koje napravite automatski podesiti cenu na primljeni
      Fangout zahtev.
      <br />
      <br />
      Ovo će Vam uštedeti vreme potrebno za pregovor, podešavanje cene i
      plaćanje od strane korisnika koji je zahtevao uslugu. Podesite F-Agent,
      zavalite se i pustite ga da uradi posao za Vas.
    </div>
  ),
  switch_to_professional: 'Prebaci se na Profesionalni nalog',
  switch_to_regular: 'Prebaci se na Regularan nalog',
  premium_expires: 'Premium aktivan do: ',
  premium_buy_description:
    'Premium omogućava dve dodatne opcije koje štede vreme i povećavaju efikasnost.',
  buy: 'Kupi',
  premium_label_months: 'Trajanje Premiuma',
  premium_label_months_desc: '(meseci)',
  premium_price_1: 'Cena: 50',
  premium_price_2: '/mesec',
  premium_price_discount_1: '(za 12+ meseci 40',
  premium_price_discount_2: 'mesečno)',
  premium_to_pay: 'Za uplatu:',
  premium_months: 'mesec(i)',
  premium_payment: 'Plaćanje Premiuma',
  event: 'događaj',
  SHOW: 'Događaj',
  ONLINE_SHOW: 'Online događaj',
  HYBRID_SHOW: 'Hibridni događaj',
  show_desc: 'Regularan uživo događaj.',
  online_desc: 'Samo Online događaj. Samo Streaming ulaznica je dostupna.',
  hybrid_desc:
    'Uživo događaj sa online streaming-om. Obe ulaznice i za uživo događaj i za streaming su dostupne.',
  show_budget: 'Budget',
  show_budget_desc:
    'Necessary cost of the event (artists, place, transport etc.)',
  show_confirmation_point: 'Confirmation point',
  show_confirmation_point_desc:
    'This is a sum that includes the Budget + 6% Fangout fee.\n' +
    'If the confirmation point is not reached, all buyers\n' +
    'automatically get refund and event is get canceled.',
  show_funding_until: 'Funding until',
  show_funding_until_desc:
    'If the Budget is not reached up to this date event will \n' +
    'be canceled and funds will be refunded.',
  show_image: 'Poster događaja',
  show_upload_image: 'Dodaj poster',
  show_event_image_size: '(1200x1600 preporučeno)',
  show_tickets: 'Tipovi ulaznica',
  show_streaming_ticket: 'Streaming ticket',
  show_streaming_ticket_price: 'Cena streaming ulaznice',
  show_streaming_ticket_desc: 'Opis streaming ulaznice',
  show_ticket_title: 'Naziv ulaznice',
  show_ticket_desc: 'Opis ulaznice',
  show_tickets_number: 'Broj slobodnih ulaznica',
  show_tickets_price: 'Cena po ulaznici',
  show_add_ticket: 'Dodaj tip ulaznice',
  show_count_profit: 'Zarada',
  show_count_total_tickets: 'Total tickets',
  show_count_funding_goal: 'Funding goal',
  show_age_restriction: 'Starosna granica',
  show_no_restriction: 'Bez ograničenja',
  show_is_private: 'Privatni događaj',
  show_private_event: 'Prostup samo preko URL-a',
  show_publish: 'Objavi',
  show_publish_message: (
    <div>
      Are you sure you want to publish this Show.
      <br /> Check the settings again, future edit will be limited for some
      fields.
    </div>
  ),
  show_delete_desc: 'Da li ste sigurni?',
  show_ticket_soldout: 'Rasprodato',
  show_sold: 'Prodato',
  show_earned: 'Zarada',
  ticket: 'Ulaznica',
  tickets: 'Ulaznice',
  label_shows: 'Događaji',
  label_my_shows: 'Moji Događaji',
  more: 'Više',
  less: 'Manje',
  invalidator: 'Invalidator',
  invalidated: 'Invalidirano',
  invalidate: 'Invalidiraj',
  invalidation_success: 'Uspešno invalidirano!',
  default_qr_scanner_message: 'Nema rezultata',
  share_the_invalidator: 'Podeli Invalidator',
  show_go_to_public_page: 'Idi na stranicu događaja',
  statistics: 'Statistika',
  total: 'Ukupno',
  ticket_type: 'Tip ulaznice',
  purchased: 'Kupljeno',
  need_login_show_message: 'Da biste kupili ulaznicu morate biti ulogovani.',
  ticket_payment: 'Plaćanje ulaznica',
  ticket_payment_ticketsTotal: 'Ulaznice ukupno',
  ticket_payment_discount: 'popust',
  ticket_payment_bankFee: 'provizija banke',
  ticket_order: 'Korpa',
  show_cannot_cancel:
    'NOTE: If event starts within next 5 days, once published it can not be canceled.',
  no_streaming: 'Još uvek nema emitovanja.',
  no_tickets: 'Trenutno nemate kupljenih ulaznica',
  no_requests: 'Nema primljenih zahteva za saradnju',
  no_requests_sent: 'Nema poslatih zahteva',
  no_requests_history: 'No istorijata',
  no_following: 'Ne pratite nikoga.',
  no_following_locations:
    'Dodajte lokaciju kako biste bili obavešteni ako neko bude dostupan na njoj.',
  or: 'ili',
  qty: 'kom',
  checkout: 'Plaćanje',
  welcome_note: 'Nov način povezivanja sa publikom.',
  register: 'Registruj se',
  welcome_has_account: 'Već imate nalog?',
  login: 'Login',
  login_dont_have_account: 'Nemate nalog?',
  register_acceptance: 'Registracijom prihvatate',
  register_terms_of_use: 'Uslove korišćenja',
  label_public: 'Javno vidljivo',
  label_private: 'Privatni podatak',
  label_username: 'Korisničko ime',
  label_password: 'Lozinka',
  label_verify_password: 'Potvrdite Lozinku',
  registration: 'Registracija',
  professional_acc: 'Profesionalni nalog',
  register_to_earn_money: 'zaradi novac',
  continue: 'Nastavi',
  show_allowed_invalidations: 'Broj dozvoljenih ulazaka',
  show_allowed_invalidations_help_title: 'Broj dozvoljenih ulazaka',
  show_allowed_invalidations_help_message: (
    <div>
      <div>
        Kada je ulaznica namenjena višednevnom događaju `Broj dozvoljenih
        ulazaka` treba da se podesi onaj broj na koliko dana želimo korisniku da
        omogućumo ulazak na događaj.
      </div>
      <div>
        Ulaznicu je moguće invalidirati samo jednom u prozoru od 12 sati, tako
        da je dupli ulazak nemoguć.
      </div>
    </div>
  ),
  ticketInvalidatedInLast12Hrs:
    'Ulaznica je već čekirana u poslednjih 12 sati.',
  tickets_price: 'Cene ulaznica',
  live: 'Live',
};
