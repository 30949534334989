import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { getPublicShow } from '../createShow/showService';
import { dateTimeFormat } from '../myShows/myShows';
import More from '../../components/more/More';
import { ITicketType } from '../createShow/createShow';
import TicketRow from './TicketRow/TicketRow';
import { currencyMap } from '../../constants';
import { Button, Container } from '../../styling/globalStyling';
import Heading from '../../components/heading/heading';
import { Content } from '../createFangout/createFangout';
import NeedLogin from '../../components/needLogin/needLogin';
import { openMaps, shareFeature } from '../../utils';
import { Share } from '../publicProfile/publicProfile';
import config from '../../config';
import Tabs from 'src/components/tabs/tabs';
import { editorContentToHtml } from 'src/components/textEditor/utils';

const Show = () => {
  const params = useParams();
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const { data } = useQuery(['show', params.showId], ({ queryKey }) =>
    getPublicShow(queryKey[1])
  );

  const [qty, setQty] = useState<any>({});
  const [total, setTotal] = useState(0);

  const show = data?.data || {};

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });

  useEffect(() => {
    const getTotal = () => {
      let total = 0;
      for (let key in qty) {
        const ticket = show.ticketTypes.find(
          (t: ITicketType) => t.id === parseInt(key)
        );
        if (ticket) {
          total += qty[key] * ticket.ticketPrice;
        }
      }

      return total;
    };

    setTotal(getTotal());
  }, [qty, show.ticketTypes]);

  const checkout = () => {
    ReactGA.event({
      action: 'Click',
      category: 'Button',
      label: 'checkout_tickets',
      value: show.showTitle,
    });
    const tickets = show.ticketTypes
      .filter((t) => !!qty[t.id])
      .map((t) => ({ ...t, numberOfTickets: parseInt(qty[t.id]) }));

    const data = {
      tickets,
      currency: show.currency || 'EUR',
      showId: show.id,
      showTitle: show.showTitle,
    };

    history.push({ pathname: '/ticketspay', state: { data } });
  };

  const share = (e) => {
    e.stopPropagation();
    shareFeature(`${config.appUrl}/#/show/${show.id}`);
  };

  const descriptionHtml = editorContentToHtml(show?.description);

  return (
    <Container>
      <Heading title={show.showTitle} showLogo />
      <Share style={{ top: '20px', right: '16px', position: 'absolute' }}>
        <i className={'icon-share'} onClick={(e) => share(e)} />
      </Share>
      <Content className={'noscroll'}>
        {show.eventImage && (
          <ShowImg src={show.eventImage} alt={show.showTitle} />
        )}
        <ShowDetails className={''}>
          <div className={'small mb1'}>
            <ShowOwner>
              <img
                className={'userImage'}
                src={show.celebrityDto?.profilePhoto}
                alt={'show-owner'}
              />
              <div
                className={'owner'}
                onClick={() => history.push(`/public/${show.celebrityDto?.id}`)}
              >
                @{show.celebrityDto?.username}
              </div>
            </ShowOwner>
            <div className={'date'}>
              <i className={'fas fa-clock'} />
              {moment(show?.showDate).format(dateTimeFormat)} -{' '}
              {moment(show?.endDate).format(dateTimeFormat)}
            </div>
            <div className={'location link'} onClick={(e) => openMaps(e, show)}>
              <i className={'fas fa-location-dot'} />
              {show?.location?.title}
            </div>
          </div>
          <More>
            <div className={'desc'}>
              <span
                dangerouslySetInnerHTML={{
                  __html: descriptionHtml || '',
                }}
              />
            </div>
          </More>
        </ShowDetails>
        <Tickets>
          <div className={'label'} style={{ alignItems: 'center' }}>
            {lang.tickets}
          </div>
          {show?.ticketTypes?.map((t: ITicketType, i) => {
            return (
              <TicketRow
                key={t.id}
                ticket={t}
                qty={qty}
                setQty={setQty}
                show={show}
              />
            );
          })}
          <Total className={'mt1'}>
            <div className='total'>
              {lang.total}: {total > 0 ? total : 0} {currencyMap[show.currency]}
            </div>
          </Total>
          <div className={'pullRight mt1'}>
            <NeedLogin
              customMessage={lang.need_login_show_message}
              from={history.location}
            >
              <Tabs active={3} lang={lang} />
              <div className='pb60'>
                <Button disabled={!total} onClick={checkout}>
                  {lang.checkout}
                </Button>
              </div>
            </NeedLogin>
          </div>
        </Tickets>
      </Content>
    </Container>
  );
};

export default Show;

const Total = styled.div`
  //color: ${({ theme }) => theme.gray};
  text-align: right;
  padding-right: 6px;
  font-size: 14px;
  .total {
    font-weight: 600;
    font-size: 16px;
  }
`;
const ShowImg = styled.img`
  width: 100%;
`;

export const ShowDetails = styled.div`
  padding: 0;

  .date {
    padding: 2px 4px;
  }
  .location {
    padding: 2px 4px;
  }

  i {
    margin-right: 5px;
    text-align: center;
    min-width: 16px;
  }

  .small {
    font-size: 14px;
  }

  .desc {
    padding: 2px 4px;
    text-align: justify;
  }

  .link,
  a {
    color: ${({ theme }) => theme.primary};
  }
`;

export const Tickets = styled.div`
  .label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 16px;
    text-align: center;
  }

  .pullRight {
    text-align: right;
  }
`;

export const ShowOwner = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 10px;
  padding: 6px 5px 6px 6px;
  background: rgba(255, 255, 255, 0.1);

  img.userImage {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  .owner {
    cursor: pointer;
  }
`;
