import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { EditorState } from 'draft-js';

import {
  Button,
  Container,
  Flex,
  Label,
  Link,
} from '../../styling/globalStyling';
import Heading from '../../components/heading/heading';
import GoBack from '../../components/goBack/goBack';
import {
  getShowDetails,
  getShowStatistics,
  updateShow,
} from '../createShow/showService';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Content } from '../createFangout/createFangout';
import { Tickets } from '../show/Show';
import { useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import styled from 'styled-components';
import { currencyMap, SHOW } from '../../constants';
import { TextArea } from '../../components/fangoutComponent/requestFangoutComponent';
import toast from 'react-hot-toast';
import { ShowType, Ticket } from 'src/models';
import InputNew from 'src/components/inputNew/InputNew';
import { CurrencyInput } from '../createShow/styledComponents';
import TextEditor from 'src/components/textEditor';
import classnames from 'classnames';

import style from './ShowDashboard.module.scss';
import {
  parseContent,
  stringifyContent,
} from 'src/components/textEditor/utils';
import { SettingsOption } from '../settings/settings';
import FSwitch from 'src/components/Switch/FSwitch';
import { dateTimeFormat } from '../myShows/myShows';
import { sanitizeCommaSeparatedValue } from 'src/utils';

interface ITicketStatistics {
  currency: string;
  description: string;
  id: number;
  numberOfTickets: number;
  soldTickets: number;
  ticketPrice: number;
  title: string;
}

const ShowDashboard = () => {
  const { showId } = useParams();
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const { data, isLoading } = useQuery(['myshow', showId], ({ queryKey }) =>
    getShowDetails(queryKey[1])
  );

  const [embeddedCode, setEmbeddedCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [isPrivate, setIsPrivate] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { data: statisticsData } = useQuery(
    ['showStatistics', showId],
    ({ queryKey }) => getShowStatistics(queryKey[1])
  );

  const show: ShowType = data?.data;

  useEffect(() => {
    if (show) {
      setEmbeddedCode(show?.streamingCode);
      setTickets(show?.ticketTypes);
      setIsPrivate(show?.isPrivate);
      if (show.description) {
        setEditorState(parseContent(show.description));
      }
    }
  }, [show]);

  const total = useMemo(() => {
    const total = {
      soldTickets: 0,
      totalTickets: 0,
      soldAmount: 0,
      currency: '',
    };
    const { soldTickets, totalTicketsRevenue, currency } =
      statisticsData?.data || {};
    total.soldTickets = soldTickets?.reduce(
      (acc, item) => (acc += item.soldTickets),
      0
    );
    total.totalTickets = soldTickets?.reduce(
      (acc, item) => (acc += item.numberOfTickets),
      0
    );
    total.soldAmount = totalTicketsRevenue;
    total.currency = currency;
    return total;
  }, [statisticsData]);

  const goToPublicPage = () => {
    history.push(`/show/${show?.id}`);
  };

  const save = () => {
    setLoading(true);

    const data = {
      id: show.id,
      streamingCode: embeddedCode,
      ticketTypes: tickets,
      description: stringifyContent(editorState),
      isPrivate,
    };
    updateShow(data)
      .then(() => toast.success('Saved successfully'))
      .catch(() => toast.error('There was a problem'))
      .finally(() => setLoading(false));
  };

  const handleTicketChange = (change: any, index: number, field: string) => {
    const sanitizedChange = sanitizeCommaSeparatedValue(change);
    const newTickets = [...tickets];
    newTickets.splice(index, 1, {
      ...tickets[index],
      [field]: sanitizedChange,
    });
    setTickets(newTickets);
  };

  if (show) {
    return (
      <ShowDashContainer>
        <GoBack />
        <Heading title={`${show.showTitle}`} />
        <Content className={'p016 noscroll pb300'}>
          <Flex gap={'16px'}>
            {show.eventImage && (
              <img
                className={'eventImage'}
                src={show.eventImage}
                alt={show.showTitle}
              />
            )}
            <Flex className={'column details'}>
              <div className={'small mb1'}>
                <Flex gap={'10px'} className={'date'}>
                  <i className={'fas fa-clock'} />
                  {moment(show?.showDate).format(dateTimeFormat)} -{' '}
                  {moment(show?.endDate).format(dateTimeFormat)}
                </Flex>
                <Flex gap={'10px'} className={'location'}>
                  <i className={'fas fa-location-dot'} />
                  {show?.location?.title}
                </Flex>
              </div>
            </Flex>
          </Flex>
          <Link className='underline text-bold' onClick={goToPublicPage}>
            {lang.show_go_to_public_page}
          </Link>

          <div>
            <Tickets>
              <Label style={{ textAlign: 'center', marginTop: '16px' }}>
                {lang.statistics}
              </Label>
              {statisticsData?.data?.soldTickets.map(
                (t: ITicketStatistics, i) => {
                  return (
                    <TicketStatistic key={t.id}>
                      <div className='title'>{t.title}</div>
                      <div className='sold'>
                        {t.soldTickets}/{t.numberOfTickets}pc
                      </div>
                    </TicketStatistic>
                  );
                }
              )}
              {statisticsData?.data?.soldTickets.length > 0 && total && (
                <Total>
                  <div className={'title'}>{lang.total}</div>
                  <Flex gap={'16px'} className={'numbers'}>
                    <div>
                      {lang.show_sold}: {total.soldTickets}/{total.totalTickets}
                      pc
                    </div>
                    <div>
                      {total.soldAmount} {currencyMap[total.currency]}
                    </div>
                  </Flex>
                  <div>
                    {lang.show_earned} (4% fee): {total.soldAmount * 0.96}{' '}
                    {currencyMap[total.currency]}
                  </div>
                </Total>
              )}
            </Tickets>

            {show.showType !== SHOW ? (
              <div className={'mt1'}>
                <div className={'relative'}>
                  <Label className='text-center'>
                    Embedded cod of your video streaming
                  </Label>
                  <TextArea
                    value={embeddedCode || ''}
                    className={''}
                    onChange={(e) => setEmbeddedCode(e.target.value)}
                  />
                  <div className={'fs12'}>
                    (Paste the code and make sure the iframe width is set to
                    100%)
                  </div>
                </div>

                {show.streamingCode || embeddedCode ? (
                  <div className={'fullWidth mt2'}>
                    <Label>Preview</Label>
                    <div
                      dangerouslySetInnerHTML={{ __html: embeddedCode ?? '' }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <Label style={{ textAlign: 'center' }}>{lang.tickets_price}</Label>
          {tickets.map((t, i) => (
            <div key={i} className={style.ticketWrapper}>
              <div className={style.ticketName}>
                {t.title} ({show?.ticketTypes[i].ticketPrice}
                {currencyMap[show?.currency]})
              </div>
              <InputNew
                isvalid={t.ticketPrice < 1 ? 'error' : ''}
                style={{ flexGrow: 1 }}
                type={'number'}
                label={lang.show_tickets_price}
                name={'ticketPrice'}
                value={t.ticketPrice}
                icon={currencyMap[show?.currency]}
                onChange={(e) =>
                  handleTicketChange(e.target.value, i, 'ticketPrice')
                }
                inputComponent={CurrencyInput}
                className={style.priceInput}
              />
            </div>
          ))}
          <TextEditor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
          <Label>{lang.show_is_private}</Label>
          <SettingsOption>
            <span>{lang.show_private_event}</span>
            <FSwitch onChange={setIsPrivate} checked={isPrivate} />
          </SettingsOption>
          <Button
            disabled={tickets.some((t) => t.ticketPrice < 1)}
            className={classnames(style.gratisTicketsButton, 'second')}
            onClick={() =>
              history.push({
                pathname: `/gratisGenerator/${show.id}`,
                state: show,
              })
            }
          >
            Gratis ticket generator
          </Button>
          <Button
            disabled={tickets.some((t) => t.ticketPrice < 1)}
            className={style.saveButton}
            onClick={save}
          >
            {lang.save}
          </Button>
        </Content>
        {loading && <Spinner fullscreen />}
      </ShowDashContainer>
    );
  } else if (isLoading) {
    return <Spinner />;
  } else {
    return <div>No data</div>;
  }
};

export default ShowDashboard;

const Total = styled.div`
  /* color: ${({ theme }) => theme.gray}; */
  text-align: right;

  .title {
    font-weight: 500;
    margin-bottom: 3px;
  }

  .numbers {
    justify-content: flex-end;
  }
`;

const TicketStatistic = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
`;

const ShowDashContainer = styled(Container)`
  img.eventImage {
    max-width: 60px;
    max-height: 100px;
  }

  .details {
    font-size: 14px;
  }
`;
