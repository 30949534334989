import config from '../../config';
import axios from 'axios';
import { IShow, ITicketType } from '../createShow/createShow';

export interface IInvalidateTicketData {
  showCode?: string;
  ticketInvalidationId?: string;
}
export interface ITicketDetails {
  id: number;
  invalidationDate: string | null;
  invalidationId: string;
  isValid: boolean;
  purchaseDate: string;
  purchasedTicketCurrency: string | null;
  purchasedTicketPrice: number;
  show: IShow;
  ticketType: ITicketType;
  invalidationsLeft: number;
}

export interface IExtTicket {
  id: number;
  transactionId: number;
  show: IShow;
  ticketType: string;
  invalidationDate: string;
  purchaseDate: string;
  invalidationId: string;
  ownerName: string;
  externalProvider: string;
  invalidationsLeft: number;
}

export function fetchTicketData(ticketInvalidationId: string) {
  const url = `${config.baseApi}/v1/public/tickets/findByInvalidationId`;
  const data = { ticketInvalidationId };
  return axios.create().post(url, data);
}

export function invalidateTicket(data: IInvalidateTicketData) {
  const url = `${config.baseApi}/v1/public/tickets`;
  return axios.create().put(url, data);
}

export function fetchExternalTicketData(data: IInvalidateTicketData) {
  const url = `${config.baseApi}/v1/public/external_tickets/findByInvalidationIdAndShowId`;
  return axios.create().post(url, data);
}

export function invalidateExternalTicket(data: IInvalidateTicketData) {
  const url = `${config.baseApi}/v1/public/external_tickets`;
  return axios.create().put(url, data);
}
